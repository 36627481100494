<template>
    <b-card
            no-body
            class="p-1"
    >
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >

            <!--            vandar-modal-->
            <b-modal
                    ref="vandar-modal"
                    title="ورود به وندار"
                    ok-title="استعلام وضعیت"
                    cancel-title="لغو"
                    v-model="vandarModal"
                    @ok.prevent="getStatus(transactionData[0],transactionData[1])"
            >
                <b-overlay
                        :show="check.includes(true)"
                        rounded="sm">
                    <b-form>
                        <b-form-group class="mt-1" label="نام کاربری حساب وندار">
                            <b-form-input v-model="vandar.mobile" placeholder="نام کاربری"/>
                        </b-form-group>
                        <b-form-group class="mt-1" label="رمز عبور حساب وندار">
                            <b-form-input v-model="vandar.password" placeholder="رمز عبور" type="password"/>
                        </b-form-group>
                    </b-form>
                </b-overlay>
            </b-modal>

            <div
                    class="demo-spacing-0 m-1"
                    dir="ltr"
            >
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
                    فیلتر
                    <feather-icon icon="FilterIcon"/>
                </b-button>
            </div>

            <search-and-filter
                    :is-active.sync="isActive"
                    :options="columns"
                    @filter="getData(1, perPage)"
                    @reset="getData(1, perPage)"
            />

            <!--      <history-details :is-active.sync="isDetails" :order="items[detailIndex]"/>-->

            <div class="relative-over-x">
                <b-table
                        ref="refUserListTable"
                        :items="items"
                        :small="true"
                        responsive
                        :fields="columns"
                        primary-key="id"
                        show-empty
                        empty-text="اطلاعاتی یافت نشد"
                        @sort-changed="sort($event)"
                        no-local-sort
                        style="white-space: nowrap; min-height : 235px"
                >

                    <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount,0) }}
                        </span>
                    </template>

                    <template #cell(createdAtDateTime)="data">
                        <span dir="ltr">
                            {{ $G2J(data.item.createdAtDateTime) }}
                        </span>
                    </template>

                    <template #cell(tomanTransactionStatus)="{item,index}">
                        <b-badge
                                pill
                                :variant="'light-'+statusVariant(item.tomanTransactionStatus || 'CANCELED')"
                                class="text-capitalize"
                        >
                            {{ statusLabel(item.tomanTransactionStatus) || 'نامشخص' }}
                        </b-badge>
                        <feather-icon
                                v-if="item.tomanTransactionStatus === 'PENDING'"
                                icon="RefreshCcwIcon"
                                size="20"
                                class="cursor-pointer text-warning mx-1"
                                :class="{'rotate' : check[index]}"
                                v-tooltip="'بررسی تراکنش'"
                                @click="getStatus(item.id,index)"
                        />
                    </template>

                </b-table>
            </div>

            <!-- pagination -->
            <div
                    class="demo-spacing-0 d-flex justify-content-between m-1"
                    dir="rtl"
            >
                <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        first-number
                        last-number
                        prev-class="prev-item"
                        next-class="next-item"
                        align="left"
                        @input="getData(currentPage,perPage)"
                >
                    <template #prev-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                    <template #next-text>
                        <feather-icon
                                icon="ChevronRightIcon"
                                size="18"
                        />
                    </template>
                </b-pagination>
                <div>
                    <label for="perpage">تعداد در صفحه</label>
                    <v-select
                            id="perpage"
                            v-model="perPage"
                            dir="rtl"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                            style="min-width: 85px"
                            @input="getData(1,perPage)"
                    />
                </div>
            </div>

        </b-overlay>
    </b-card>
</template>

<script>
    import {
        BPagination,
        BCard,
        BButton,
        BTable,
        // BDropdown,
        // BDropdownItem,
        BBadge,
        // BAvatar,
        BOverlay,
        BForm,
        BFormGroup,
        BFormInput,
        BModal,
    } from 'bootstrap-vue'
    import SearchAndFilter from "@/layouts/components/SearchAndFilter";
    import vSelect from 'vue-select';
    // import HistoryDetails from "@/views/Accounting/Transaction/historyDetails";

    export default {
        name: 'tomanTransactions',
        components: {
            // HistoryDetails,
            SearchAndFilter,
            BPagination,
            BCard,
            BButton,
            BTable,
            // BDropdown,
            // BDropdownItem,
            BBadge,
            // BAvatar,
            vSelect,
            BOverlay,
            BForm,
            BFormGroup,
            BFormInput,
            BModal,
        },
        data: () => ({
            test: 0,
            pageLength: 3,
            dir: false,
            searchTerm: '',
            currentPage: 1,
            perPage: 10,
            rows: 1,
            sortBy: '',
            isSortDirDesc: '',
            userData: [],
            isActive: false,
            isDetails: false,
            vandarModal: false,
            transactionData: [],
            detailIndex: 0,
            perPageOptions: [5, 10, 20, 50, 100],
            items: [],
            check: [],
            vandar: {
                mobile: '',
                password: ''
            },
            columns: [
                {
                    label: 'نام کاربر',
                    key: 'customerEmail',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'مقدار تراکنش',
                    key: 'amount',
                    sortable: true,
                    searchType: 'number'
                },
                {
                    label: ' کد پیگیری',
                    key: 'trackingCode',
                    sortable: false,
                    searchType: 'text'
                },
                {
                    label: 'کد تراکنش',
                    key: 'transId',
                    sortable: false,
                },
                {
                    label: 'تاریخ بررسی',
                    key: 'createdAtDateTime',
                    sortable: true,
                    searchType: 'date'
                },
                {
                    label: 'وضعیت',
                    key: 'tomanTransactionStatus',
                    sortable: false,
                    searchType: 'select',
                    selectOptions: [
                        {label: 'انجام شده', value: 'DONE'},
                        {label: 'لغو شده', value: 'CANCELED'},
                        {label: 'در حال انجام', value: 'PENDING'}
                    ]
                },
                /*{
                    label: 'عملیت',
                    key: 'action'
                },*/
            ],
        }),
        computed: {
            statusVariant() {
                const a = {
                    DONE: 'success',
                    PENDING: 'warning',
                    CANCELED: 'danger'
                }
                return e => a[e]
            },
            statusLabel() {
                const a = {
                    DONE: 'انجام شده',
                    PENDING: 'در حال انجام',
                    CANCELED: 'لغو شده'
                }
                return e => a[e]
            },
        },
        methods: {
            sort(e) {

                let sort = this.$toSnakeCase(e.sortBy)
                // let sort = e.sortBy
                let sorting = e.sortDesc ? 'DESC' : 'ASC'

                this.$router.push({
                    query: {
                        ...this.$route.query,
                        orderBy: sort,
                        sorting: sorting
                    }
                })
                this.getData(1, this.perPage)

            },
            async getStatus(id, i) {
                try {
                    if (!this.check[i]) {
                        this.transactionData = [id, i]
                        this.check[i] = true
                        this.$forceUpdate()
                        await this.$axios.post('/wallets/check-defray/' + id, this.vandar)
                        this.check[i] = false
                        this.vandarModal=false
                        this.$forceUpdate()
                        this.getData(this.page, this.perPage)
                    }
                } catch (e) {
                    if (e.response.data.message) {
                        if (e.response.data.message.includes('Unauthorized Error')) {
                            this.$error('نیازمند به ورود')
                            this.vandarModal = true
                        } else {
                            const err = JSON.parse(e.response.data.errors[0].match(/\[{(.*?)\}]/)[0])[0]
                            this.$error(err.error, err.errors.amount)
                        }
                    }
                    this.check[i] = false
                    this.$forceUpdate()
                }
            },

            async getData(page, perPage) {
                this.state.loading = true

                const queryParams = {
                    size: perPage,
                    page: page,
                    ...this.$route.query
                }
                const res = await this.$axios('/wallets/toman-transactions', {params: queryParams})

                this.state.loading = false
                this.items = res.data.content
                this.currentPage = res.data.number + 1
                this.rows = res.data.totalElements
            },
        },
        created() {
            this.getData()
        },
    }
</script>
<style lang="scss">
    [v-cloak] {
        opacity: 0;
    }

    .nowrap {
        white-space: nowrap;
    }

    .rotate {
        animation: rotate 1s infinite;
    }

    @keyframes rotate {
        from {
            transform: rotate(0)
        }
        to {
            transform: rotate(360deg)
        }
    }

    [dir="rtl"] .test-badge {
        font-size: 10px;
        top: -6px;
        left: -5px !important;
        min-width: 16px;
        min-height: 16px;
    }
</style>
